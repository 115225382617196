<template>
  <div
    class="wap_top_az"
    :class="$t('languages') == 'EN' ? 'wap_top_az_en' : ''"
  >
    <img :src="$t('azabordData.wapTopImg1')" alt="" />
    <div class="top_det">
      <div class="wapfont28 font700w clw100 wel_top">WELCOME!</div>
      <div class="clb100 wapfont20">
        {{ $t("AaAbordTop." + $route.query.prev + "Title") }}
      </div>
      <!-- <div
        :class="$route.query.prev == 'az' ? 'wapfont12' : 'wapfont12'"
        class="clb100 det_det"
      >
        {{ $t("AaAbordTop." + $route.query.prev + "Det") }}
      </div> -->
    </div>
    <div class="bot_btn">
      <div class="wapfont14 det_btn clf100" @click="goApply(1)">
        {{ $t("AaAbordTop." + $route.query.prev + "Btn1") }}
      </div>
      <div class="wapfont14 det_btn btn_mr clf100" @click="goApply(2)">
        {{ $t("AaAbordTop." + $route.query.prev + "Btn2") }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goApply(index) {
      if (
        !localStorage.getItem("AZG_TOKEN") ||
        localStorage.getItem("AZG_TOKEN") === null
      ) {
        this.$router.push("/login");
        return;
      }
      let applyQuery = this.$t(
        "AaAbordTop." + this.$route.query.prev + "applyQueryBtn" + index
      );
      this.$emit("goApply", applyQuery);
    }
  }
};
</script>

<style lang="less" scoped>
.wap_top_az {
  position: relative;
  width: 100%;
  height: calc(100vw * 18.4 / 37.5);
  text-align: left;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .wel_top {
    margin-bottom: calc(100vw * 2.4 / 37.5);
  }
  .top_det {
    position: absolute;
    left: calc(100vw * 2 / 37.5);
    right: calc(100vw * 2 / 37.5);
    top: calc(100vw * 1.84 / 37.5);
    z-index: 99;
  }
  .det_det {
    margin-top: calc(100vw * 1 / 37.5);
    line-height: calc(100vw * 1.7 / 37.5);
  }
  .bot_btn {
    position: absolute;
    left: calc(100vw * 2 / 37.5);
    bottom: calc(100vw * 2.3 / 37.5);
    display: flex;
  }
  .det_btn {
    // background: #fff;
    background: #bf9264;
    display: inline-block;
    padding: calc(100vw * 0.8 / 37.5) calc(100vw * 3.6 / 37.5);
    border-radius: 4px;
    margin-top: calc(100vw * 1.6 / 37.5);
    cursor: pointer;
  }
  .btn_mr {
    margin-left: calc(100vw * 2.4 / 37.5);
  }
}
.wap_top_az_en {
  height: calc(100vw * 18.4 / 37.5);
  .bot_btn {
    right: calc(100vw * 2 / 37.5);
    .det_btn {
      padding: calc(100vw * 0.8 / 37.5) calc(100vw * 1 / 37.5);
    }
  }
}
</style>
